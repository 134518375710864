<template>
  <div>
    <v-card-text>
      <v-alert v-if="!documentReady" text type="info"
        >No hay data para mostrar</v-alert
      >
      <v-card v-else outlined class="white pa-2">
        <iframe
          id="frameID"
          style="border: 2px solid rgb(237, 237, 237)"
          width="100%"
          :height="window.height"
          :src="urlReportViewer"
        ></iframe>
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-form ref="form">
          <v-select
            :items="listaReporte"
            v-model="selectedReport"
            :rules="reporteValidation"
            item-text="Nombre"
            item-value="ReporteCode"
            placeholder="Seleccione reporte"
            :disabled="processing"
            hint="Filtro por reporte"
            append-icon="keyboard_arrow_down"
            persistent-hint
          ></v-select>
          <br />
          <br />
          <v-row>
            <v-col cols="12">
        
            <v-autocomplete
              :items="listaCanales"
              v-model="canalesSelected"
              item-text="Nombre"
              item-value="CanalId"
              append-icon="keyboard_arrow_down"
              label="Canales"
              hint="Canales"
              persistent-hint
              multiple
            ></v-autocomplete>

          </v-col>
          </v-row>
          <br />
          <br />
          <v-row>
            <v-col cols="12">
              <date-selector
                label="Fecha desde"
                :fecha.sync="fechaInicio"
                :rules="dateRule"
              ></date-selector>
            </v-col>
            <v-col cols="12">
              <date-selector
                label="Fecha hasta"
                :fecha.sync="fechaFin"
                :rules="dateRule"
              ></date-selector>
            </v-col>
          </v-row>
        </v-form>
        <br />
        <br />
        <div class="mb-5 text-right">
          <v-btn
            block
            class="ma-1"
            depressed
            color="primary"
            @click="generarReporte"
          >
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiReporte from "@/api/reportes";
import apiHome from '@/api/home';
import { mapActions } from "vuex";
import { global } from "@/api/global";

let _baseurl = global.baseWebUrl + "mvc/report/";

export default {
  data: () => {
    return {
      drawerRight: true,
      selectedReport: null,
      fechaInicio: null,
      fechaFin: null,
      documentReady: true,
      listaReporte: [],
      listaCanales:[],
      canalesSelected:[],
      processing: false,
      dateRule: [(v) => !!v || "Seleccionar una fecha"],
      urlReportViewer: global.baseWebUrl + "loader.html",
      reporteValidation: [(v) => !!v || "Debe seleccionar un reporte"],
      window: {
        width: 0,
        height: 0,
      },
      breadcrumb: [
        {
          text: "Reportes",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
  
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    async getCanales(){
      const responseCanales =  await apiHome.getCanales();
      
      var canales = responseCanales.data;

      this.listaCanales = canales;

    },
    getReportes() {
      if (this.$store.getters.user.canalid) {
        apiReporte
          .GetGeneralReportListByCanalID(this.$store.getters.user.canalid)
          .then((response) => {
            this.listaReporte = response.data;
            this.documentReady = false;
          })
          .catch((error) => {
            this.setError(error);
            this.documentReady = false;
          });
      } else {
        this.setMessage("Favor seleccionar un corte primero");
      }
    },
    generarReporte() {
      if (this.$refs.form.validate()) {
        this.documentReady = true;
        this.processing = true;

        var canales = JSON.stringify(
              this.canalesSelected
            );
    
        var self = this;
        setTimeout(function () {
       
          self.processing = false;
      
          self.urlReportViewer =
            _baseurl +
            "ReporteEmpleadoViewer?" +
            "ReporteCode=" +
            self.selectedReport +
            "&CanalId=" +
            canales +
            "&FechaInicio=" +
            self.fechaInicio +
            "&FechaFin=" +
            self.fechaFin;
        }, 2000);
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 210;
    },
  },
  mounted() {
 
      this.getReportes();
      this.getCanales();
      
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>